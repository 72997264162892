function Viewport(breakpoints) {

    // Get the breakpoint config
    this.breakpoints = breakpoints;

    // Constuct the breakpoint for the media query
    this.mediaQuery = function(breakpoint) {
        return '(' + this.breakpoints[breakpoint].type + ': ' + this.breakpoints[breakpoint].size + 'px)';
    }

    // Check if the media query matches
    this.matchMedia = function(breakpoint) {
        return window.matchMedia(this.mediaQuery(breakpoint));
    }

    // Check if the media query matches
    this.matches = function(breakpoint) {
        return window.matchMedia(this.mediaQuery(breakpoint)).matches;
    }

    // Construct the custom media query
    this.customMediaQuery = function(breakpoint) {
        return breakpoint;
    }

    // Check if the custom media query matches
    this.customMatchMedia = function(breakpoint) {
        return window.matchMedia(this.customMediaQuery(breakpoint));
    }

    // Check if the custom media query matches
    this.customMatches = function(breakpoint) {
        return window.matchMedia(this.customMediaQuery(breakpoint)).matches;
    }

    // Watch for viewport resize
    this.resize = function(callback) {
        var windowResizing;

        window.addEventListener('resize', function() {
            clearTimeout(windowResizing);
            windowResizing = setTimeout(function() {
                if (typeof callback == 'function') {
                    callback();
                }
            }, 100);
        });
    }
}

var preventScroll = {
    active: false,
    on: function() {
        var htmlEl = document.querySelector('html');
        var body = document.body;

        // Determine the `scrollTop` to use. Some browsers require checking the
        // `body`, others use `html`.
        var bodyScrollTop = body.scrollTop;
        var htmlScrollTop = htmlEl.scrollTop;
        var scrollTop = bodyScrollTop ? bodyScrollTop : htmlScrollTop;

        // Store the current value of the htmlEl's styles – we're about to override
        // them.
        this.currentPosition = htmlEl.style.position;
        this.currentOverflow = htmlEl.style.overflowY;
        this.currentWidth = htmlEl.style.width;

        // Fixing the position of the `htmlEl` prevents the page from scrolling
        // at all.
        htmlEl.style.position = 'fixed';
        // Setting `overflowY` to `scroll` ensures that any scrollbars that are
        // around stick around. Otherwise, there would be a "jump" when the page
        // becomes unscrollable as the bar would vanish.
        htmlEl.style.overflowY = 'scroll';
        // This makes sure that the page doesn't collapse (usually your CSS will
        // prevent this, but it's best to be safe)
        htmlEl.style.width = '100%';
        // Scoot down the `htmlEl` to be in the same place that the user had
        // scrolled to.
        htmlEl.style.top = (0 - scrollTop) + 'px';

        // Set active state
        this.active = true;
    },

    off: function() {
        var htmlEl = document.querySelector('html');
        var body = document.body;

        // Reset `htmlEl` to the original styles.
        htmlEl.style.position = this.currentPosition;
        htmlEl.style.overflowY = this.currentOverflow;
        htmlEl.style.width = this.currentWidth;

        // Retrieve our original scrollTop from the htmlEl's top

        htmlEl.classList.add('no-smooth-scroll');

        var scrollTop = -parseInt(htmlEl.style.top);
        // Return us to the original scroll position. Once again, we set this on
        // both the `body` and the `htmlEl` to be safe.
        htmlEl.scrollTop = scrollTop;
        body.scrollTop = scrollTop;

        htmlEl.classList.remove('no-smooth-scroll');

        // Unset active state
        this.active = false;
    }
}


function Search() {
    let viewport = new Viewport(AndrewMartin.breakpoints);

    this.html = document.querySelector('html');
    this.search = document.querySelector('.js-search');
    this.searchForm = document.querySelector('.js-search__form');
    this.searchInput = document.querySelector('.js-search__input');
    this.searchPageInput = document.querySelector('.js-search__page-input');
    this.searchToggle = document.querySelectorAll('.js-search--toggle');
    this.header = document.querySelector('.header');
    this.headertop = document.querySelector('.header__top');
    this.headerNavToggle = document.querySelector('.js-header-nav__toggle');

    this.init = () => {
        if (!this.search) {
            return;
        }
        this.watchToggle();
        this.watchSearch();
        this.watchPageResize();
    }

    // Watch the toggle for click events
    this.watchToggle = () => {
        this.searchToggle.forEach((toggle, i) => {
            toggle.addEventListener('click', this.toggleSearch.bind(this, i));
        });
    }

    // Watch the component toggle for click events
    this.watchSearch = () => {
        this.search.addEventListener('click', this.deactivateSearch.bind(this));
        this.searchForm.addEventListener('click', (e) => {
            e.stopPropagation();
        });
    }

    // Toggle the search component
    this.toggleSearch = (id) => {
        if (this.isSearchActive()) {
            this.deactivateSearch();
        } else {
            if (AndrewMartin.header.isNavActive()) {
                AndrewMartin.header.deactivateNavItem();
                AndrewMartin.header.deactivateNav();
            }
            this.activateSearch(id);
        }
    }

    // Is the search active?
    this.isSearchActive = () => {
        return this.search.classList.contains('search--active');
    }

    // Activate the search
    this.activateSearch = (id) => {
        if (this.searchPageInput) {
            this.searchPageInput.focus();
        } else {
            setTimeout(() => {
                this.search.classList.add('search--active');
                this.searchToggle.forEach((toggle) => {
                    toggle.classList.add('header-actions__item--search-active');
                });
                this.searchInput.focus();
            }, 1);

            this.html.setAttribute('search-active', true);
            this.search.setAttribute('search-toggle', id);
            this.search.classList.add('search--activating');
            this.searchToggle.forEach((toggle) => {
                toggle.setAttribute('aria-expanded', true);
            });
            this.setHeight();
            if (!preventScroll.active) {
                preventScroll.on();
            }
        }
    }

    // Deactivate the search
    this.deactivateSearch = () => {
        this.search.classList.remove('search--active');
        this.searchToggle.forEach((toggle) => {
            toggle.classList.remove('header-actions__item--search-active');
        });

        setTimeout(() => {
            this.html.removeAttribute('search-active');
            this.search.removeAttribute('search-toggle');
            this.search.classList.remove('search--activating');
            this.searchToggle.forEach((toggle) => {
                toggle.setAttribute('aria-expanded', true);
            });
            this.resetHeight();
            preventScroll.off();
        }, 300);
    }

    // Set the height of the search component
    this.setHeight = () => {
        this.search.style.height = window.innerHeight - this.search.getBoundingClientRect().top + 'px';
    }

    // Reset the height of the search component
    this.resetHeight = () => {
        this.search.style.height = null;
    }

    // Watch for the page to resize
    this.watchPageResize = () => {

        viewport.resize(() => {
            if (this.isSearchActive()) {
                this.setHeight();
            }
        })
    }
}

AndrewMartin.search = new Search();

AndrewMartin.search.init();
